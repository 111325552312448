import { Inject, Injectable, SecurityContext } from '@angular/core';
import { Params } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import {
  LinkParamsGetter,
  LinkRendererComponent,
} from '../features/grid/link-renderer/link-renderer.component';
import { APP_CONFIG, AppConfig } from '../app.config';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(
    private sanitizer: DomSanitizer,
    @Inject(APP_CONFIG) private config: AppConfig,
  ) {}

  sanitizeCell(content: string | null): string {
    // Protect against XSS - Ag-grid cell renderers do not sanitize cell input.
    const value = this.sanitizer.sanitize(SecurityContext.HTML, content);
    const formatted = value ? value : '';
    return `${formatted}`;
  }

  linkRenderer(paramsGetter: LinkParamsGetter) {
    return {
      cellRenderer: LinkRendererComponent,
      cellRendererParams: {
        paramsGetter,
      },
    };
  }

  /**
   * Intended to be used with the linkRenderer, this method will get the params for creating a link
   * renderer that sends the user to a graphpad admin page in a new tab.
   * Note that graphpad admin currently has a staging and prod environment.
   */
  public getGraphpadAdminLinkParams(id: string, graphpadPageLinkType: 'customer' | 'subscription') {
    if (!id) {
      return undefined;
    }

    const gpAdminBaseUrl =
      this.config.graphpadAdminBaseUrl ?? 'https://admin.insightfulscience.com';

    let link;

    switch (graphpadPageLinkType) {
      case 'customer':
        link = `${gpAdminBaseUrl}/apps/index.cfm?action=admin:customer.edit&customerID=${id}`;
        break;
      case 'subscription':
        link = `${gpAdminBaseUrl}/apps/index.cfm?subscriptionID=${id}&action=admin:subscription.view`;
        break;
    }

    return {
      text: id,
      href: link,
      openInNewTab: true,
    };
  }

  openNewLink(content: string | null, link: string) {
    const value = this.sanitizer.sanitize(SecurityContext.HTML, content);
    return `<a href="${link}" target="_blank">${value}</a>`;
  }

  static getQueryParams(url: string): Params {
    const parts = url.split('?');
    if (parts.length < 2) {
      return {};
    } else {
      // Get the query parameters but discard hash parameters
      const querySection = parts[1].split('#')[0];
      const paramSections = querySection.split('&');
      const params: Params = {};
      paramSections.forEach((param) => {
        const keyAndValue = param.split('=');
        params[keyAndValue[0]] = keyAndValue[1];
      });
      return params;
    }
  }
}
