import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../../../shared/util.service';

export type LinkParamsGetter = (cell: ICellRendererParams) => {
  /** The text to render */
  text: string;
  /** The url to navigate to, can be a relative url eg '/users' */
  href?: string;
  /** Set to true to open a new tab for the url being navigated to */
  openInNewTab?: boolean;
};

@Component({
  selector: 'bx-link-renderer',
  templateUrl: 'link-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
})
export class LinkRendererComponent implements ICellRendererAngularComp {
  href?: string;
  text?: string;
  openInNewTab?: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  /**
   * Navigates to the href url of this class.
   */
  goToLink(e: Event) {
    e.preventDefault(); // prevents us jumping to the href of the <a></a> element.
    if (!this.href) {
      return;
    }

    if (this.href.startsWith('http://') || this.href.startsWith('https://')) {
      this.navigateExternally(this.href);
    } else {
      this.navigateWithinApp(this.href);
    }
  }

  agInit(params: ICellRendererParams & { paramsGetter: LinkParamsGetter }): void {
    const data = params.paramsGetter(params);

    if (data) {
      this.text = data.text;
      this.href = data.href;
      this.openInNewTab = data.openInNewTab;
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  private navigateExternally(url: string) {
    if (this.openInNewTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }

  private navigateWithinApp(url: string) {
    const baseRoute = url.split('?')[0];
    const queryParams = UtilService.getQueryParams(url);

    if (this.openInNewTab) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([baseRoute], {
          queryParams,
          relativeTo: this.route,
        }),
      );
      window.open(url, '_blank');
    } else {
      this.router.navigate([baseRoute], {
        queryParams,
        replaceUrl: false,
        relativeTo: this.route,
      });
    }
  }
}
