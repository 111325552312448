import { Entity } from '../models/entity.model';
import { fullName } from '../../shared/utils/user';
import { UserDetailsStatus } from '@geneious/nucleus-api-client';
import { UserRole } from 'src/nucleus/v2/models/user-roles.model';

/**
 * I think this is for a generic user, whereas the "user" in v1 is for "THE" current user which is
 * a different model.
 */
export default class User implements Entity {
  id: string;
  name: string;
  organizationId: string;
  email: string;
  givenName: string;
  familyName?: string;
  isAdmin: boolean;
  created: Date;
  role: string;
  customerID: string;
  lastLoginAt: string | undefined;
  status: UserDetailsStatus;

  static fromJson(json: any): User {
    const user = new User();
    user.id = json.id;
    user.organizationId = json.organizationID;
    user.email = json.email;
    user.givenName = json.givenName;
    user.familyName = json.familyName;
    user.created = json.created;
    user.status = json.status;
    user.role = User.calculateRole(json.roles);
    user.lastLoginAt = json.lastLoginAt;
    user.isAdmin = User.isAdmin(json.roles);
    user.customerID = json.customerID;
    return user;
  }

  toServerModel() {
    const payload = {
      email: this.email,
      givenName: this.givenName,
      familyName: this.familyName,
      organizationID: this.organizationId,
    };
    if (this.isAdmin) {
      return {
        ...payload,
        isAdmin: true,
        roles: [UserRole.RegularUser, UserRole.OrgAdmin],
      };
    } else {
      return { ...payload, roles: [UserRole.RegularUser] };
    }
  }

  get fullName() {
    return fullName(this);
  }

  static isAdmin(roles: UserRole[]): boolean {
    return !!roles.find((role) => role === UserRole.NucleusAdmin || role === UserRole.OrgAdmin);
  }

  static isNucleusAdmin(roles: UserRole[]): boolean {
    return roles.some((role) => UserRole.NucleusAdmin === role);
  }

  static calculateRole(roles: UserRole[]) {
    const hasGeneiousAdminRole = roles.includes(UserRole.NucleusAdmin);
    const hasAdminRole = roles.includes(UserRole.OrgAdmin);
    const hasUserRole = roles.includes(UserRole.RegularUser);

    if (hasGeneiousAdminRole && hasAdminRole && hasUserRole) {
      return 'Nucleus admin';
    } else if (hasAdminRole && hasUserRole) {
      return 'Admin';
    } else if (hasUserRole) {
      return 'User';
    }

    return `Incomplete (${roles.join(', ')})`;
  }
}
